<template>
  <div class="screensaver" ref="screensaver">
    <!-- screensaver:headline & description START -->
    <div class="headline-description" v-on:click="closeScreensaver">
      <div
        class="headline"
        v-html="$t('terminal.screensaver.headline').replace('in der AnyBox', 'bei MPREIS')"
      ></div>
      <div class="description" v-html="$t('terminal.screensaver.description')"></div>
    </div>
    <!-- screensaver:headline & description END -->

    <!-- screensaver:button(start) START -->
    <div class="start" v-on:click="closeScreensaver">
      <div class="text" v-html="$t('terminal.screensaver.buttons.start')"></div>
      <particles />
    </div>
    <!-- screensaver:button(start) END -->

    <!-- screensaver:info START -->
    <div class="info">
      <div class="info" v-html="$t('terminal.screensaver.excerpt')"></div>
      <div class="language">
        <div class="language-headline" v-html="$t('terminal.loading.language.select')"></div>
        <div
          :class="['language-single', activeLanguage === 'de_AT' ? 'active' : '']"
          v-on:click="changeLanguage('de_AT')"
          v-on:touchstart="touchStart($event)"
          v-on:touchend="touchEnd($event)"
          v-on:touchcancel="touchEnd($event)"
        >
          <span class="flag">
            <img width="" height="56" :src="germanyFlag" />
          </span>
          <!--
            <span class="country" v-html="$t('terminal.screensaver.flags.germany')"></span>
          -->
        </div>
        <div
          :class="['language-single', activeLanguage === 'en_US' ? 'active' : '']"
          v-on:click="changeLanguage('en_US')"
          v-on:touchstart="touchStart($event)"
          v-on:touchend="touchEnd($event)"
          v-on:touchcancel="touchEnd($event)"
        >
          <span class="flag">
            <img width="" height="56" :src="ukFlag" />
          </span>
          <!--
            <span class="country" v-html="$t('terminal.screensaver.flags.us')"></span>
          -->
        </div>
      </div>
    </div>
    <!-- screensaver:info END -->

    <!-- help START -->
    <div :class="['help']">
      <div class="help-inner">
        <div class="description" v-on:click="dialogOpenBug()">
          <p v-html="$t('terminal.index.dialog.bug.title')"></p>
        </div>
        <div class="actions">
          <div class="language" v-if="false">
            <div class="language-headline" v-html="$t('terminal.loading.language.select')"></div>
            <div
              :class="['language-single', activeLanguage === 'de_AT' ? 'active' : '']"
              v-on:click="changeLanguage('de_AT', false)"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event)"
              v-on:touchcancel="touchEnd($event)"
            >
              <span class="flag">
                <img width="" height="56" :src="germanyFlag" />
              </span>
              <!--
                <span class="country" v-html="$t('terminal.screensaver.flags.germany')"></span>
              -->
            </div>
            <div
              :class="['language-single', activeLanguage === 'en_US' ? 'active' : '']"
              v-on:click="changeLanguage('en_US', false)"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event)"
              v-on:touchcancel="touchEnd($event)"
            >
              <span class="flag">
                <img width="" height="56" :src="ukFlag" />
              </span>
              <!--
                <span class="country" v-html="$t('terminal.screensaver.flags.us')"></span>
              -->
            </div>
          </div>

          <div
            class="btn btn-default btn-default-white"
            v-on:click="dialogOpen()"
            v-on:touchstart="touchStart($event)"
            v-on:touchend="touchEnd($event)"
            v-on:touchcancel="touchEnd($event)"
            v-html="$t('terminal.index.help.manual')"
          ></div>

          <!-- made-by START -->
          <div :class="['made-by']">
            <span>
              made by
              <!-- eslint-disable max-len -->
              <svg
                class="ecc-logo"
                width="658"
                height="216"
                viewBox="0 0 658 216"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M344.707 96.8666C349.001 101.16 355.955 101.183 360.277 96.9174C374.521 82.862 403.264 54.4994 403.264 54.4994L387.636 38.876C387.636 38.876 359.039 67.0943 344.758 81.186C342.662 83.2537 341.478 86.0717 341.469 89.0156C341.459 91.9591 342.625 94.7855 344.707 96.8666Z"
                  fill="#00C3FF"
                />
                <path
                  d="M64.8374 78.6648C64.8374 73.3499 63.2853 70.3194 60.1847 67.1013C57.0737 63.8839 52.9882 62.2933 47.9079 62.2933C43.1694 62.2933 38.6341 63.848 35.4133 67.0292C32.2001 70.1748 30.6435 73.2412 29.6266 78.6648H64.8374ZM82.104 54.3389C90.1191 63.1245 94.1241 75.1998 94.1241 90.5655C94.1241 92.9155 94.0654 94.7227 93.9536 95.9883H30.304C31.7699 101.158 34.3091 105.142 37.9208 107.908C41.5322 110.674 45.9359 112.055 51.1283 112.055C55.0723 112.055 58.8821 111.267 62.5499 109.683C66.2185 108.107 69.5755 105.793 72.6277 102.749L88.3675 118.49C83.6267 123.798 77.9014 127.828 71.1886 130.591C64.4708 133.356 56.8807 134.738 48.416 134.738C38.5975 134.738 30.0206 132.822 22.6842 128.986C15.3485 125.15 9.73339 119.731 5.84087 112.731C1.94988 105.735 0 97.6151 0 88.36C0 78.9962 1.972 70.7169 5.9286 63.5581C9.8749 56.4002 15.461 50.8687 22.6842 46.9639C29.9058 43.0592 38.2603 41.1074 47.7397 41.1074C62.638 41.1074 74.089 45.5175 82.104 54.3389Z"
                  fill="white"
                />
                <path
                  d="M154.628 66.0169C149.209 66.0169 144.809 68.0777 141.425 72.1991C138.034 76.3205 136.346 81.6354 136.346 88.1792C136.346 94.8314 138.034 100.218 141.425 104.267C144.809 108.327 149.209 110.359 154.628 110.359C162.977 110.359 168.956 106.918 172.571 100.038L194.752 112.731C191.137 119.727 185.717 125.15 178.497 128.986C171.274 132.825 162.81 134.741 153.103 134.741C143.736 134.741 135.5 132.825 128.388 128.986C121.281 125.15 115.778 119.727 111.884 112.731C107.989 105.735 106.041 97.6151 106.041 88.36C106.041 78.9962 108.016 70.7169 111.971 63.5581C115.915 56.4002 121.476 50.8687 128.642 46.9639C135.811 43.0592 144.075 41.1074 153.446 41.1074C162.586 41.1074 170.71 43.023 177.821 46.7831C184.926 50.5795 190.457 55.8936 194.408 62.7995L172.571 75.489C168.28 69.1621 162.304 66.0169 154.628 66.0169Z"
                  fill="white"
                />
                <path
                  d="M251.177 66.0169C245.757 66.0169 241.361 68.0777 237.973 72.1991C234.586 76.3205 232.897 81.6354 232.897 88.1792C232.897 94.8314 234.586 100.218 237.973 104.267C241.361 108.327 245.757 110.359 251.177 110.359C259.524 110.359 265.508 106.918 269.123 100.038L291.3 112.731C287.684 119.727 282.269 125.15 275.045 128.986C267.825 132.825 259.358 134.741 249.655 134.741C240.283 134.741 232.048 132.825 224.937 128.986C217.828 125.15 212.326 119.727 208.432 112.731C204.538 105.735 202.59 97.6151 202.59 88.36C202.59 78.9962 204.564 70.7169 208.519 63.5581C212.463 56.4002 218.024 50.8687 225.193 46.9639C232.355 43.0592 240.627 41.1074 249.994 41.1074C259.138 41.1074 267.258 43.023 274.369 46.7831C281.477 50.5795 287.005 55.8936 290.956 62.7995L269.123 75.489C264.832 69.1621 258.852 66.0169 251.177 66.0169Z"
                  fill="white"
                />
                <path
                  d="M411.528 42.1182H439.734L462.798 105.789L485.307 42.1182H513.392L479.028 133.725H445.729L411.528 42.1182Z"
                  fill="white"
                />
                <path
                  d="M528.159 42.1185H554.822V133.721H528.159V42.1185ZM552.887 4.48297C555.873 7.44784 557.373 11.3159 557.373 16.0522C557.373 20.6797 555.873 24.5482 552.887 27.5852C549.893 30.6221 546.083 32.1402 541.458 32.1402C536.827 32.1402 533.02 30.6221 530.03 27.5852C527.04 24.5482 525.547 20.6797 525.547 16.0522C525.547 11.3159 527.04 7.44784 530.03 4.48297C533.02 1.48186 536.827 0 541.458 0C546.083 0 549.893 1.48186 552.887 4.48297Z"
                  fill="white"
                />
                <path
                  d="M621.51 111.291C624.895 109.089 627.037 106.24 627.943 102.748V94.7942H609.998C601.534 94.7942 597.301 97.9395 597.301 104.266C597.301 107.43 598.399 109.936 600.604 111.797C602.805 113.663 605.818 114.595 609.661 114.595C614.172 114.595 618.125 113.492 621.51 111.291ZM646.903 49.6746C653.899 55.4952 657.452 63.6297 657.568 74.1507V133.725H627.943V123.396C621.853 131.183 612.651 135.073 600.349 135.073C590.643 135.073 582.996 132.451 577.41 127.21C571.82 121.956 569.033 115.047 569.033 106.465C569.033 97.7587 572.048 90.9982 578.086 86.1532C584.123 81.3087 592.785 78.8141 604.07 78.7062H627.943V77.6935C627.943 73.2826 626.445 69.884 623.455 67.5348C620.459 65.1482 616.146 63.9916 610.503 63.9916C606.671 63.9916 602.352 64.6058 597.557 65.8355C592.756 67.0645 587.937 68.8721 583.082 71.2583L574.785 50.9398C582.237 47.5416 589.371 45.0471 596.204 43.4199C603.031 41.7572 610.114 40.9619 617.446 40.9619C630.085 40.9619 639.902 43.8536 646.903 49.6746Z"
                  fill="white"
                />
                <path
                  d="M348.016 64.266L370.146 42.6472L368.65 42.1208C363.269 40.2274 357.624 39.2803 351.986 39.2803C339.264 39.2803 326.553 44.1309 316.844 53.8319C307.17 63.4947 302.303 76.1899 302.258 88.847V89.217C302.303 101.918 307.169 114.577 316.842 124.255L316.843 124.255C326.52 133.932 339.194 138.791 351.876 138.84H352.23C364.924 138.791 377.594 133.932 387.271 124.255L387.272 124.255C401.223 110.299 405.112 90.1202 399.04 72.664L398.907 72.2818L376.581 94.4037C375.601 98.9454 373.353 103.266 369.82 106.808C360.007 116.622 344.102 116.621 334.289 106.806C324.477 96.9879 324.475 81.0875 334.287 71.2843L334.288 71.2832C338.162 67.4098 342.99 65.08 348.016 64.266Z"
                  fill="#00C3FF"
                />
                <path
                  d="M645.621 215.897C643.214 215.897 641.047 215.484 639.122 214.659C637.196 213.799 635.683 212.596 634.583 211.048C633.482 209.501 632.932 207.696 632.932 205.633H639.225C639.362 207.18 639.964 208.452 641.03 209.449C642.131 210.447 643.661 210.945 645.621 210.945C647.65 210.945 649.231 210.464 650.366 209.501C651.501 208.504 652.068 207.232 652.068 205.684C652.068 204.481 651.707 203.501 650.985 202.744C650.297 201.988 649.421 201.403 648.355 200.99C647.323 200.578 645.879 200.131 644.022 199.649C641.684 199.03 639.775 198.411 638.296 197.792C636.852 197.139 635.614 196.142 634.583 194.801C633.551 193.46 633.035 191.672 633.035 189.436C633.035 187.373 633.551 185.568 634.583 184.021C635.614 182.473 637.059 181.287 638.915 180.462C640.772 179.636 642.921 179.224 645.363 179.224C648.836 179.224 651.673 180.1 653.874 181.854C656.109 183.574 657.347 185.946 657.587 188.972H651.088C650.985 187.666 650.366 186.548 649.231 185.62C648.097 184.691 646.601 184.227 644.744 184.227C643.059 184.227 641.684 184.657 640.618 185.516C639.552 186.376 639.019 187.614 639.019 189.23C639.019 190.331 639.345 191.242 639.999 191.964C640.686 192.652 641.546 193.202 642.578 193.614C643.609 194.027 645.019 194.474 646.807 194.956C649.18 195.609 651.105 196.262 652.584 196.916C654.097 197.569 655.369 198.583 656.401 199.959C657.467 201.3 658 203.105 658 205.375C658 207.197 657.501 208.917 656.504 210.533C655.541 212.149 654.114 213.456 652.223 214.453C650.366 215.416 648.165 215.897 645.621 215.897Z"
                  fill="white"
                />
                <path
                  d="M625.056 215.536H619.176L601.484 188.766V215.536H595.604V179.637H601.484L619.176 206.355V179.637H625.056V215.536Z"
                  fill="white"
                />
                <path
                  d="M570.475 215.897C567.139 215.897 564.062 215.123 561.242 213.576C558.457 211.994 556.239 209.811 554.588 207.025C552.972 204.206 552.164 201.042 552.164 197.535C552.164 194.027 552.972 190.881 554.588 188.095C556.239 185.31 558.457 183.144 561.242 181.596C564.062 180.015 567.139 179.224 570.475 179.224C573.845 179.224 576.922 180.015 579.708 181.596C582.527 183.144 584.745 185.31 586.361 188.095C587.977 190.881 588.786 194.027 588.786 197.535C588.786 201.042 587.977 204.206 586.361 207.025C584.745 209.811 582.527 211.994 579.708 213.576C576.922 215.123 573.845 215.897 570.475 215.897ZM570.475 210.791C572.847 210.791 574.962 210.258 576.819 209.192C578.676 208.091 580.12 206.544 581.152 204.549C582.218 202.521 582.751 200.182 582.751 197.535C582.751 194.887 582.218 192.566 581.152 190.571C580.12 188.577 578.676 187.047 576.819 185.981C574.962 184.915 572.847 184.382 570.475 184.382C568.102 184.382 565.987 184.915 564.13 185.981C562.273 187.047 560.812 188.577 559.746 190.571C558.714 192.566 558.199 194.887 558.199 197.535C558.199 200.182 558.714 202.521 559.746 204.549C560.812 206.544 562.273 208.091 564.13 209.192C565.987 210.258 568.102 210.791 570.475 210.791Z"
                  fill="white"
                />
                <path d="M545.372 179.688V215.536H539.492V179.688H545.372Z" fill="white" />
                <path
                  d="M532.505 179.688V184.484H522.963V215.536H517.083V184.484H507.489V179.688H532.505Z"
                  fill="white"
                />
                <path
                  d="M478.531 179.688V202.537C478.531 205.254 479.236 207.3 480.645 208.675C482.09 210.051 484.084 210.739 486.629 210.739C489.208 210.739 491.202 210.051 492.612 208.675C494.056 207.3 494.778 205.254 494.778 202.537V179.688H500.658V202.434C500.658 205.357 500.022 207.833 498.75 209.862C497.478 211.891 495.776 213.404 493.644 214.401C491.512 215.398 489.156 215.897 486.577 215.897C483.998 215.897 481.643 215.398 479.511 214.401C477.413 213.404 475.745 211.891 474.507 209.862C473.269 207.833 472.651 205.357 472.651 202.434V179.688H478.531Z"
                  fill="white"
                />
                <path
                  d="M454.632 210.79H466.753V215.536H448.752V179.688H454.632V210.79Z"
                  fill="white"
                />
                <path
                  d="M423.623 215.897C420.288 215.897 417.21 215.123 414.39 213.576C411.605 211.994 409.387 209.811 407.736 207.025C406.12 204.206 405.312 201.042 405.312 197.535C405.312 194.027 406.12 190.881 407.736 188.095C409.387 185.31 411.605 183.144 414.39 181.596C417.21 180.015 420.288 179.224 423.623 179.224C426.993 179.224 430.071 180.015 432.856 181.596C435.676 183.144 437.893 185.31 439.51 188.095C441.126 190.881 441.934 194.027 441.934 197.535C441.934 201.042 441.126 204.206 439.51 207.025C437.893 209.811 435.676 211.994 432.856 213.576C430.071 215.123 426.993 215.897 423.623 215.897ZM423.623 210.791C425.996 210.791 428.11 210.258 429.967 209.192C431.824 208.091 433.268 206.544 434.3 204.549C435.366 202.521 435.899 200.182 435.899 197.535C435.899 194.887 435.366 192.566 434.3 190.571C433.268 188.577 431.824 187.047 429.967 185.981C428.11 184.915 425.996 184.382 423.623 184.382C421.25 184.382 419.136 184.915 417.279 185.981C415.422 187.047 413.96 188.577 412.894 190.571C411.863 192.566 411.347 194.887 411.347 197.535C411.347 200.182 411.863 202.521 412.894 204.549C413.96 206.544 415.422 208.091 417.279 209.192C419.136 210.258 421.25 210.791 423.623 210.791Z"
                  fill="white"
                />
                <path
                  d="M386.923 215.897C384.516 215.897 382.35 215.484 380.424 214.659C378.498 213.799 376.985 212.596 375.885 211.048C374.784 209.501 374.234 207.696 374.234 205.633H380.527C380.665 207.18 381.266 208.452 382.332 209.449C383.433 210.447 384.963 210.945 386.923 210.945C388.952 210.945 390.534 210.464 391.668 209.501C392.803 208.504 393.37 207.232 393.37 205.684C393.37 204.481 393.009 203.501 392.287 202.744C391.6 201.988 390.723 201.403 389.657 200.99C388.625 200.578 387.181 200.131 385.324 199.649C382.986 199.03 381.077 198.411 379.599 197.792C378.154 197.139 376.916 196.142 375.885 194.801C374.853 193.46 374.337 191.672 374.337 189.436C374.337 187.373 374.853 185.568 375.885 184.021C376.916 182.473 378.361 181.287 380.218 180.462C382.074 179.636 384.224 179.224 386.665 179.224C390.138 179.224 392.975 180.1 395.176 181.854C397.411 183.574 398.649 185.946 398.889 188.972H392.39C392.287 187.666 391.668 186.548 390.534 185.62C389.399 184.691 387.903 184.227 386.046 184.227C384.361 184.227 382.986 184.657 381.92 185.516C380.854 186.376 380.321 187.614 380.321 189.23C380.321 190.331 380.647 191.242 381.301 191.964C381.988 192.652 382.848 193.202 383.88 193.614C384.911 194.027 386.321 194.474 388.109 194.956C390.482 195.609 392.408 196.262 393.886 196.916C395.399 197.569 396.672 198.583 397.703 199.959C398.769 201.3 399.302 203.105 399.302 205.375C399.302 207.197 398.804 208.917 397.806 210.533C396.843 212.149 395.416 213.456 393.525 214.453C391.668 215.416 389.468 215.897 386.923 215.897Z"
                  fill="white"
                />
                <path
                  d="M338.48 184.434V194.956H350.859V199.753H338.48V210.739H352.407V215.536H332.6V179.637H352.407V184.434H338.48Z"
                  fill="white"
                />
                <path
                  d="M317.599 215.536L309.346 201.196H304.859V215.536H298.979V179.688H311.358C314.109 179.688 316.43 180.169 318.321 181.132C320.247 182.095 321.674 183.384 322.602 185C323.565 186.616 324.046 188.422 324.046 190.416C324.046 192.754 323.359 194.886 321.983 196.812C320.642 198.703 318.562 199.993 315.742 200.681L324.614 215.536H317.599ZM304.859 196.503H311.358C313.558 196.503 315.209 195.952 316.309 194.852C317.444 193.752 318.012 192.273 318.012 190.416C318.012 188.559 317.461 187.115 316.361 186.083C315.261 185.017 313.593 184.484 311.358 184.484H304.859V196.503Z"
                  fill="white"
                />
                <path
                  d="M273.85 215.897C270.514 215.897 267.437 215.123 264.617 213.576C261.832 211.994 259.614 209.811 257.963 207.025C256.347 204.206 255.539 201.042 255.539 197.535C255.539 194.027 256.347 190.881 257.963 188.095C259.614 185.31 261.832 183.144 264.617 181.596C267.437 180.015 270.514 179.224 273.85 179.224C277.22 179.224 280.297 180.015 283.083 181.596C285.902 183.144 288.12 185.31 289.736 188.095C291.353 190.881 292.161 194.027 292.161 197.535C292.161 201.042 291.353 204.206 289.736 207.025C288.12 209.811 285.902 211.994 283.083 213.576C280.297 215.123 277.22 215.897 273.85 215.897ZM273.85 210.791C276.222 210.791 278.337 210.258 280.194 209.192C282.051 208.091 283.495 206.544 284.527 204.549C285.593 202.521 286.126 200.182 286.126 197.535C286.126 194.887 285.593 192.566 284.527 190.571C283.495 188.577 282.051 187.047 280.194 185.981C278.337 184.915 276.222 184.382 273.85 184.382C271.477 184.382 269.362 184.915 267.505 185.981C265.648 187.047 264.187 188.577 263.121 190.571C262.089 192.566 261.574 194.887 261.574 197.535C261.574 200.182 262.089 202.521 263.121 204.549C264.187 206.544 265.648 208.091 267.505 209.192C269.362 210.258 271.477 210.791 273.85 210.791Z"
                  fill="white"
                />
                <path
                  d="M250.512 179.688V184.484H240.97V215.536H235.09V184.484H225.496V179.688H250.512Z"
                  fill="white"
                />
                <path
                  d="M207.004 215.897C204.597 215.897 202.43 215.484 200.505 214.659C198.579 213.799 197.066 212.596 195.965 211.048C194.865 209.501 194.315 207.696 194.315 205.633H200.608C200.745 207.18 201.347 208.452 202.413 209.449C203.513 210.447 205.044 210.945 207.004 210.945C209.032 210.945 210.614 210.464 211.749 209.501C212.884 208.504 213.451 207.232 213.451 205.684C213.451 204.481 213.09 203.501 212.368 202.744C211.68 201.988 210.803 201.403 209.737 200.99C208.706 200.578 207.262 200.131 205.405 199.649C203.066 199.03 201.158 198.411 199.679 197.792C198.235 197.139 196.997 196.142 195.965 194.801C194.934 193.46 194.418 191.672 194.418 189.436C194.418 187.373 194.934 185.568 195.965 184.021C196.997 182.473 198.441 181.287 200.298 180.462C202.155 179.636 204.304 179.224 206.746 179.224C210.219 179.224 213.056 180.1 215.256 181.854C217.492 183.574 218.729 185.946 218.97 188.972H212.471C212.368 187.666 211.749 186.548 210.614 185.62C209.479 184.691 207.984 184.227 206.127 184.227C204.442 184.227 203.066 184.657 202 185.516C200.934 186.376 200.401 187.614 200.401 189.23C200.401 190.331 200.728 191.242 201.381 191.964C202.069 192.652 202.929 193.202 203.96 193.614C204.992 194.027 206.402 194.474 208.19 194.956C210.563 195.609 212.488 196.262 213.967 196.916C215.48 197.569 216.752 198.583 217.784 199.959C218.85 201.3 219.383 203.105 219.383 205.375C219.383 207.197 218.884 208.917 217.887 210.533C216.924 212.149 215.497 213.456 213.606 214.453C211.749 215.416 209.548 215.897 207.004 215.897Z"
                  fill="white"
                />
                <path
                  d="M173.775 179.688V184.484H164.233V215.536H158.353V184.484H148.759V179.688H173.775Z"
                  fill="white"
                />
                <path
                  d="M135.614 215.536L127.361 201.196H122.874V215.536H116.994V179.688H129.373C132.124 179.688 134.445 180.169 136.336 181.132C138.262 182.095 139.689 183.384 140.617 185C141.58 186.616 142.062 188.422 142.062 190.416C142.062 192.754 141.374 194.886 139.998 196.812C138.657 198.703 136.577 199.993 133.757 200.681L142.629 215.536H135.614ZM122.874 196.503H129.373C131.574 196.503 133.224 195.952 134.325 194.852C135.459 193.752 136.027 192.273 136.027 190.416C136.027 188.559 135.477 187.115 134.376 186.083C133.276 185.017 131.608 184.484 129.373 184.484H122.874V196.503Z"
                  fill="white"
                />
                <path
                  d="M101.609 208.212H86.5995L84.0205 215.536H77.8824L90.7259 179.637H97.5344L110.378 215.536H104.188L101.609 208.212ZM99.9587 203.415L94.1301 186.755L88.25 203.415H99.9587Z"
                  fill="white"
                />
                <path
                  d="M71.237 179.688V215.536H65.3569V190.984L54.4219 215.536H50.3471L39.3606 190.984V215.536H33.4805V179.688H39.8248L52.4103 207.799L64.9442 179.688H71.237Z"
                  fill="white"
                />
                <path
                  d="M13.1315 215.897C10.7245 215.897 8.55812 215.484 6.63247 214.659C4.70682 213.799 3.19381 212.596 2.09343 211.048C0.993061 209.501 0.442871 207.696 0.442871 205.633H6.73563C6.87318 207.18 7.47495 208.452 8.54093 209.449C9.6413 210.447 11.1715 210.945 13.1315 210.945C15.1604 210.945 16.7421 210.464 17.8769 209.501C19.0117 208.504 19.579 207.232 19.579 205.684C19.579 204.481 19.218 203.501 18.4959 202.744C17.8081 201.988 16.9313 201.403 15.8653 200.99C14.8337 200.578 13.3894 200.131 11.5326 199.649C9.19428 199.03 7.28581 198.411 5.80719 197.792C4.36295 197.139 3.12503 196.142 2.09343 194.801C1.06183 193.46 0.546031 191.672 0.546031 189.436C0.546031 187.373 1.06183 185.568 2.09343 184.021C3.12503 182.473 4.56927 181.287 6.42615 180.462C8.28303 179.636 10.4322 179.224 12.8736 179.224C16.3467 179.224 19.1836 180.1 21.3843 181.854C23.6195 183.574 24.8574 185.946 25.0981 188.972H18.599C18.4959 187.666 17.8769 186.548 16.7421 185.62C15.6074 184.691 14.1116 184.227 12.2547 184.227C10.5697 184.227 9.19427 184.657 8.12829 185.516C7.0623 186.376 6.52931 187.614 6.52931 189.23C6.52931 190.331 6.85598 191.242 7.50933 191.964C8.19706 192.652 9.05673 193.202 10.0883 193.614C11.1199 194.027 12.5298 194.474 14.3179 194.956C16.6906 195.609 18.6162 196.262 20.0948 196.916C21.6079 197.569 22.8802 198.583 23.9118 199.959C24.9778 201.3 25.5107 203.105 25.5107 205.375C25.5107 207.197 25.0121 208.917 24.0149 210.533C23.0521 212.149 21.625 213.456 19.7338 214.453C17.8769 215.416 15.6762 215.897 13.1315 215.897Z"
                  fill="white"
                />
              </svg>
              <!-- eslint-enable max-len -->
            </span>
          </div>
          <!-- made-by END -->
        </div>
      </div>
    </div>
    <!-- help END -->

    <!-- help:modal START -->
    <dialog-default
      :title="dialogTitle"
      :headline="dialogHeadline"
      :description="dialogDescription"
      :type="dialogContentType"
      :dialogVisible="dialogVisible"
      :dialogClose="dialogClose"
    />
    <!-- help:modal END -->

    <!-- help:modal START -->
    <dialog-default-bug
      :title="dialogTitleBug"
      :headline="dialogHeadlineBug"
      :description="dialogDescriptionBug"
      :type="dialogContentTypeBug"
      :dialogVisible="dialogVisibleBug"
      :dialogClose="dialogCloseBug"
    />
    <!-- help:modal END -->

    <!-- loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
      :loadingType="'languages'"
    />
    <!-- oading END -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions } from "vuex";

import { loadLanguageAsync } from "../plugins/vue-i18n";

import ICONS from "../utils/icons";

const { flags } = ICONS;

export default {
  name: "screensaver",
  components: {
    DialogDefault: () => import("../components/shared/dialog/Default.vue"),
    DialogDefaultBug: () => import("../components/shared/dialog/DefaultBug.vue"),
    LoadingDefault: () => import("../components/shared/loading/Default.vue"),
    Particles: () => import("../components/screensaver/Particles.vue"),
  },
  data() {
    return {
      /**
       * Flags
       */
      ukFlag: flags.unitedKingdom,
      germanyFlag: flags.germany,
      activeLanguage: "",

      /**
       * Dialog
       */
      dialogVisible: false,
      dialogTitle: this.$t("terminal.index.dialog.title"),
      dialogHeadline: this.$t("terminal.index.dialog.headline"),
      dialogDescription: this.$t("terminal.index.dialog.description"),
      dialogContentType: "manual",

      /**
       * Dialog (Bug)
       */
      dialogVisibleBug: false,
      dialogTitleBug: this.$t("terminal.index.dialog.bug.title"),
      dialogHeadlineBug: this.$t("terminal.index.dialog.bug.headline"),
      dialogDescriptionBug: "",
      dialogContentTypeBug: "manual",

      prevRoute: null,

      /**
       * Scan
       */
      articleEAN: "",
      articleEANTest: "",

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t("terminal.loading.language.headline.init"),
      loadingExcerpt: this.$t("terminal.loading.language.description.init"),
    };
  },
  created() {
    if (this.$route.query.token && this.$route.query.locale) {
      localStorage.setItem("boxToken", this.$route.query.token);
      localStorage.setItem("boxLang", this.$route.query.locale);
      localStorage.setItem("boxLangFromRouteQuery", this.$route.query.locale);
      localStorage.setItem("registerID", this.$route.query.registerID);
    }

    // If we don't want the active language to be the one passed through the URL
    // during the initial terminal load, but instead the last actively clicked language,
    // then we can delete this if/else code block.
    if (localStorage.getItem("boxLangFromRouteQuery")) {
      this.activeLanguage = localStorage.getItem("boxLangFromRouteQuery");

      this.changeLanguage(this.activeLanguage, true);
    } else {
      this.activeLanguage = localStorage.getItem("boxLang");

      this.changeLanguage(this.activeLanguage, false);
    }

    if (this.$route.query.showQrcodeTest) {
      localStorage.setItem("showQrcodeTest", true);
    } else {
      localStorage.setItem("showQrcodeTest", false);
    }

    window.addEventListener("keydown", this.keyDownHandler);
  },
  methods: {
    ...mapActions(["addItem"]),

    changeLanguage(lang, firstInit) {
      this.activeLanguage = "";

      loadLanguageAsync(lang).then(() => {
        if (!firstInit) {
          this.loadingHeadline = this.$t("terminal.loading.language.headline");
          this.loadingExcerpt = this.$t("terminal.loading.language.description");

          this.loadingVisible = true;
        }

        setTimeout(() => {
          this.activeLanguage = localStorage.getItem("boxLang");

          this.loadingVisible = false;
        }, 750);
      });
    },

    keyDownHandler(e) {
      // 4001724819004 // 4001686315354 // 2704493005006
      const keyCode = e.keyCode || e.which;
      const code = e.code || "";
      let char = e.key;

      if (keyCode === 89 && code === "KeyY" && char === "Y") char = "Z";
      else if (keyCode === 89 && code === "KeyY" && char === "y") char = "z";
      else if (keyCode === 90 && code === "KeyZ" && char === "Z") char = "Y";
      else if (keyCode === 90 && code === "KeyZ" && char === "z") char = "y";

      if (char.length > 1) char = "";

      // scan articles
      if (this.articleEANTest === "") {
        this.articleEAN += char;

        if (e.keyCode === 13) {
          this.closeScreensaver(this.articleEAN);
          setTimeout(() => {
            this.articleEAN = "";
          }, 350);
        }
      }
    },

    closeScreensaver(ean) {
      this.$refs.screensaver.classList.add("closing");
      this.createSession(
        localStorage.getItem("boxToken"),
        localStorage.getItem("boxLang"),
        ean,
        localStorage.getItem("registerID")
      );
    },

    createSession(token, locale, ean, registerID) {
      const formData = new FormData();
      formData.append("token", token);
      formData.append("locale", locale);
      formData.append("registerID", registerID);
      this.$http
        .post(
          `${localStorage.getItem("anybox_terminal_api_url")}/createTerminalSession`,
          formData,
          {
            headers: {
              "X-Api-Key": `${localStorage.getItem("anybox_terminal_api_key")}`,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            const user = response.data;
            const auth = user.token;

            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit("SET_AUTH", auth);
            localStorage.setItem("auth", auth);

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.user = user;
            this.$store.commit("SET_USER", user);
            localStorage.setItem("user", JSON.stringify(user));

            // clear input
            setTimeout(() => {
              // add product
              if (ean.length > 6) {
                this.$router.push({ path: "/cart", query: { screensaverean: ean } });
              } else {
                this.$router.push("/cart");
              }
            }, 105);
          }
        })
        .catch((error) => {
          this.loginError = true;
          if (!error.response) {
            // network error
            this.errorStatus = "Error: Network Error";
          } else {
            console.clear();

            this.errorStatus = error.response.data;
            this.loadingVisible = false;
            this.serverErrorMessages = this.errorStatus.errors;

            this.serverErrorMessages.forEach((message) => {
              this.error(`${this.$t(`error.${message.code}`)}`);
            });

            this.$router.push("/");

            // reset login error variable
            setTimeout(() => {
              this.loginError = false;
            }, 3000);

            // delete created notification dom element
            setTimeout(() => {
              if (document.querySelector(".el-notification")) {
                const elem = document.querySelector(".el-notification");
                elem.remove();
              }
            }, 3500);
          }
        });
    },

    dialogOpen() {
      this.dialogTitle = this.$t("terminal.index.dialog.title");

      this.dialogVisible = true;
    },

    dialogClose() {
      this.dialogVisible = false;
    },

    dialogOpenBug() {
      this.dialogTitleBug = this.$t("terminal.index.dialog.bug.title");
      this.dialogHeadlineBug = this.$t("terminal.index.dialog.bug.headline");

      this.dialogVisibleBug = true;
    },

    dialogCloseBug() {
      this.dialogVisibleBug = false;
    },

    touchStart(event) {
      event.target.classList.add("pressed");
    },

    touchEnd(event) {
      event.target.classList.remove("pressed");
    },

    success() {
      this.$notify({
        message: this.$t("cart.product.success"),
        type: "success",
        position: "bottom-right",
        showClose: false,
        duration: 1500,
      });
    },

    error(message) {
      if (message) {
        this.$notify({
          message: this.$t(`${message}`),
          type: "error",
          position: "bottom-right",
          showClose: false,
          duration: 3000,
        });
      } else {
        this.$notify({
          message: this.$t("cart.product.failed"),
          type: "error",
          position: "bottom-right",
          showClose: false,
          duration: 3000,
        });
      }
    },
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
};
</script>
